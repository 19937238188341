import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { scoreColors } from "../../utils/index"

const ScoreCard = styled.div`
  .totalScore {
    display: inline-block;
    padding: 0px;
    background: ${props => props.color};
    color: #fff;
    border-radius: 5px;
  }

  .title {
    display: block;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    background: #000;
    padding: 5px;
    text-transform: uppercase;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .score {
    display: inline-block;
    font-size: 70px;
    font-weight: 900;
    line-height: 50px;
    color: #fff;
    padding: 10px;

    ${props =>
    props.solid &&
    css`
        text-shadow: none;
      `}
  }

  .max-score {
    display: inline-block;
  }

  .separator {
    margin: 10px 0;
    height: 1px;
    display: block;
    background: rgba(255, 255, 255, 0.4);

    ${props =>
    props.solid &&
    css`
        background: #dfdfdf;
      `}
  }
`

const ScoreBar = styled.div`
  width: 100%;
  height: 12px;
  background: #eceaf9;
  display: block;
  margin-bottom: 10px;
  position: relative;
  border-radius: 50px;

  & > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${props => props.width};
  background: ${props => props.theme.scoreBarColor ? props.theme?.[props.theme.scoreBarColor] : props.theme.secondaryColor};
  border-radius: 50px;
  }
`

export default props => {
  const { item, scoreProperties, solid } = props
  const { properties: itemProperties } = item
  let properties = []
  let totalScoreColor = scoreColors[0].color
  let totalScorePercentage = itemProperties?.["total-score"]?.data?.value / 10 * 100

  scoreColors.forEach(scoreColor => {
    if (totalScorePercentage > scoreColor.threshold) {
      totalScoreColor = scoreColor.color
    }
  })

  scoreProperties.forEach(scoreProp => {
    const itemProperty = itemProperties[scoreProp]
    if (
      itemProperty &&
      !isNaN(itemProperty?.data?.value)
    ) {
      properties.push({
        title: itemProperty.fieldName,
        score: itemProperty?.data?.value,
        maxScore: "10",
      })
    }
  })

  return (
    <ScoreCard className={props.className} solid={solid ? 1 : undefined} color={totalScoreColor}>
      <div
        className="d-flex"
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      ></div>
      {properties.map((property, i) => {
        let scorePercentage = (property.score / 10 * 100)

        if (scorePercentage > 100) {
          scorePercentage = 100
        }

        let color = scoreColors[0].color
        scoreColors.forEach(scoreColor => {
          if (scorePercentage > scoreColor.threshold) {
            color = scoreColor.color
          }
        })

        return (
          <div
            className="d-flex"
            style={{
              flexDirection: "column",
            }}
            key={i}
          >
            <div
              className="d-flex"
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              key={i}
            >
              <span className="text-left">{property.title}</span>
              <span className="text-right mb-2">
                {parseFloat(property.score).toFixed(1)}
              </span>
            </div>
            <ScoreBar width={scorePercentage + "%"} color={color} ><div /></ScoreBar>

          </div>
        )
      })}
    </ScoreCard>
  )
}
