import React from "react"
import cloudinary from "../../utils/cloudinary";
import { depositIcons, getAffiliateLinkWithMetadata } from "../../utils/index";
import styled from "@emotion/styled";
import { useTheme } from '@emotion/react'
import Rating from "./rating/index"
import { Grid, Col } from "react-flexbox-grid"
import { CtaButton, SmallLabel } from "./styledComponents"

const BottomStickyBarContainer = styled.div`
  background: #fff;
  z-index: 999;

  .bonus {
    font-weight: 600;
    font-size: 15px;
  }

  .depositMethods {
    color: ${props => props.theme.textFaded};
    font-size: 12px;
  }

  @media only screen and (min-width: 768px) {
    .ctaButton {
      padding: 15px 80px;
    }

    .bonus {
      font-size: 22px;
    }
  }
`

const StickyBarLogo = styled.img`
  height: 60px;
  width: 60px;
  padding: 2px;
  object-fit: contain;
  border-radius: 50%;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    height: 75px;
    width: 75px;
  }
`

export default ({props}) => {
  const { provider, site, disableBonuses = false } = props
  const { properties } = provider;
  const backgroundColor = properties["background-color"]?.data?.value;
  const theme = useTheme()
  const nakedAffiliateLink = provider?.properties?.["affiliate-link"]?.data?.value

  return (
    <BottomStickyBarContainer className="d-flex align-items-center py-2 px-2 px-md-0">
      <Grid className="px-0 d-flex" style={{justifyContent:"space-between"}}>
      <div className="d-flex align-items-center">
        <StickyBarLogo
          src={cloudinary.url(
            provider?.properties?.logotype?.resolved?.[0]
              ?.url,
            { height: 100 }
          )}
          className="mr-2 mr-sm-4"
          backgroundColor={backgroundColor}
          alt={provider?.name}
        />
        <div>
          <div className="d-flex align-items-center">
            {!disableBonuses ? (
              <span className="bonus d-inline-block mr-3">
                {provider?.["bonus"] || provider?.["bonus-text"]}
              </span>
            ):null}
            {provider?.properties?.["total-score"]?.data?.value ? (
              <div className="pt-1 d-none d-md-block">
                <Rating value={provider?.properties?.["total-score"]?.data?.value / 2} activeColor={theme?.accentColor} />
              </div>
            ) : null}
          </div>
          {depositIcons({provider})?.length ? (
            <span className="depositMethods d-none d-md-flex align-items-center mt-2">
              <SmallLabel className="mr-3">{provider?.properties?.["deposit-method-taxonomies"]?.fieldName || "Deposit methods"}</SmallLabel>
              {depositIcons({provider})?.map(item => {
                return <img src={item.url} alt={item.name} className="mr-3" style={{height:"15px", maxWidth: "75px"}} />
              })}
            </span>
          ):null}
        </div>
      </div>

        <Col className="d-flex align-items-center">
          <CtaButton
            className="outlink ctaButton"
            href={getAffiliateLinkWithMetadata({affiliateLink: nakedAffiliateLink, position: `cta_sticky`, resourceId: provider?.allanId})}
            onClick={() => {
              window._paq.push([
                "trackEvent",
                `cta_sticky`,
                provider?.name,
                "Outclick: Button",
              ]);
            }}
            target="_blank"
            rel="noindex nofollow noopener noreferrer"
          >
            { site["provider-page-cta"] ||
              site["cta"] ||
              "Play now"}
          </CtaButton>
        </Col>
      </Grid>
    </BottomStickyBarContainer>
  )
}
