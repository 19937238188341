import React, { Component } from "react"
import raf from "raf"

export default class ScrollInNav extends Component {
  static defaultProps = {
    scrollInHeight: 200,
  }

  fixedStyle = {
    position: "fixed",
    WebkitTransition: "all .2s ease-in-out",
    MozTransition: "all .2s ease-in-out",
    OTransition: "all .2s ease-in-out",
    transition: "all .2s ease-in-out",
    left: this.props.rtl ? "auto" : 0,
    right: this.props.rtl ? "auto" : 0,
    zIndex: 9999,
  }

  lockedStyle = {
    position: "absolute",
    left: this.props.rtl ? "auto" : 0,
    right: this.props.rtl ? "auto" : 0,
    bottom: 0,
    zIndex: 9999,
  }

  hiddenStyle = {
    WebkitTransform: "translateY(100%)",
    MsTransform: "translateY(100%)",
    transform: "translateY(100%)",
    bottom: 0,
  }

  scrolledInStyle = {
    WebkitTransform: "translateY(0)",
    MsTransform: "translateY(0)",
    transform: "translateY(0)",
    bottom: 0,
    height: this?.props?.height || "unset"
  }

  hiddenStyleTop = {
    WebkitTransform: "translateY(-100%)",
    MsTransform: "translateY(-100%)",
    transform: "translateY(-100%)",
  }

  scrolledInStyleTop = {
    WebkitTransform: "translateY(0)",
    MsTransform: "translateY(0)",
    transform: "translateY(0)",
    top: 0,
  }


  constructor(props) {
    super(props)

    this.state = {
      hidden: true,
      didShow: false // Used for lazyloading images in scrollInNav
    }

    this.handlingScrollUpdate = false
    this.scrollnav = React.createRef();

  }

  getScrollY = () => {
    if (window.pageYOffset !== undefined) {
      return window.pageYOffset
    } else if (window.scrollTop !== undefined) {
      return window.scrollTop
    } else {
      return (
        document.documentElement ||
        document.body.parentNode ||
        document.body
      ).scrollTop
    }
  }

  handleScroll = () => {
    if (!this.handlingScrollUpdate) {
      this.handlingScrollUpdate = true
      raf(this.update)
    }
  }

  update = () => {
    let currentScrollY = this.getScrollY()
    const hidden = currentScrollY < this?.props?.scrollInHeight
    this.setState({
      hidden: hidden,
      didShow: !hidden || this.state.didShow,
      locked: (currentScrollY + this?.scrollnav?.current?.clientHeight) > this?.props?.stop
    })

    this.handlingScrollUpdate = false
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    const { top } = this.props
    let renderStyle = this.fixedStyle

    if (top) {
      renderStyle.top = 0

      renderStyle = this.state.hidden
        ? { ...renderStyle, ...this.hiddenStyleTop }
        : { ...renderStyle, ...this.scrolledInStyleTop }
    } else {
      renderStyle = this.state.hidden
        ? { ...renderStyle, ...this.hiddenStyle }
        : { ...renderStyle, ...this.scrolledInStyle }
    }

    return (
      <div className="scroll-in-nav" ref={this.scrollnav} style={this.state.locked ? this.lockedStyle : renderStyle}>
        {this.state.didShow && this.props.children}
      </div>
    )
  }
}
