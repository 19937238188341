import React from "react"
import styled from "@emotion/styled"
import Scrollspy from "react-scrollspy"
import { basicSlug } from "../../utils/index"

const SectionMenuContainer = styled.div`
  padding: 0 !important;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  height: 45px;
  overflow: hidden;
  background: ${props => props.transparent ? "transparent" : "#fff"};

  & ul {
    white-space: nowrap;
    padding-inline-start: 0;
    padding-inline-end: 0;
    margin-block-end: 0;
    margin-block-start: 0;
    margin: 0 !important;
    padding: 0 !important;
    background: transparent;
  }

  @media (min-width: 768px) {
    position: relative;
    top: auto;
    left: auto;
    right: auto
    padding: 15px 0 !important;
    margin: 0 !important;
    overflow: hidden;
    height: auto;
  }

  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
    height:0px;
  }
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;



  .anchor {
    color: #2d3047;
    display: inline-block;
    margin-right: 20px;
    line-height: 40px;
    border-bottom: 5px solid #fff;
    background: #fff;
    padding: 0 15px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.primaryColor};
    }

    &.is-current {
      font-weight: 600;
      color: ${props => props.theme.secondaryColor};
      border-bottom: 5px solid ${props => props.theme.secondaryColor};
    }

    @media (min-width: 768px) {
      background: transparent;
      display: block;
      font-size: 16px;
      margin: 5px 0;
      position: relative;
      padding-left: 10px;
      line-height: 25px;
      border-bottom: none;

      &.subSection {
        padding-left: 15px;
        font-size: 14px;
      }

      &.is-current {
        border: 0;

        &:before {
          content: "";
          background: ${props => props.theme.secondaryColor};
          position: absolute;
          left: 0px;
          width: 4px;
          bottom: 0;
          top: 0;

        }
      }
    }
  }
`

const SectionMenuInnerContainer = styled.div`
  @media (max-width: 768px) {
    padding: 0px;
    overflow-x: scroll;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    transition: max-height 1s ease-in-out;
  }
  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
`

class SectionMenu extends React.Component {
  scrollSpyDidUpdate(props) {
    if (!window) return false
    const screenWidth = window.innerWidth || 0
    const container = document.getElementById("sectionMenuContainer")
    const currentSectionElement = document.getElementsByClassName(
      "is-current"
    )[0]
    if (!currentSectionElement) {
      return null
    }

    const activeLeftPos = currentSectionElement.getBoundingClientRect().left

    if (activeLeftPos < 0) {
      container.scrollLeft = currentSectionElement.scrollLeft
    } else if (activeLeftPos > screenWidth) {
      container.scrollLeft = activeLeftPos
    }
  }
  render() {
    const { reviewSections, transparent=false } = this.props
    let addedAvoidDuplicates = []

    if (!reviewSections) return null

    return (
      <SectionMenuContainer transparent={transparent}>
        <SectionMenuInnerContainer
          innerRef={this.ref}
          id="sectionMenuContainer"
        >
          <Scrollspy
            items={reviewSections && reviewSections.map(item => "" + basicSlug(item.slug))}
            currentClassName="is-current"
            onUpdate={props => this.scrollSpyDidUpdate(props)}
          >
            {reviewSections && reviewSections.map((item, i) => {
              const { heading, intro, anchorOnly, isLink, slug } = item
              if (
                !heading ||
                (!intro && !anchorOnly) ||
                !anchorOnly ||
                addedAvoidDuplicates.indexOf(basicSlug(slug)) > -1
              ) {
                return null
              }
              addedAvoidDuplicates.push(basicSlug(slug))

              if (isLink) {
                return (
                  !item.hidden && (
                    <a className="anchor"
                      href={item.slug}
                      key={i}
                    >
                      {"< " + heading.replace(/^[ \t]+|[*_^#+{}\\]+|[ \t]+$/gi, '')}
                    </a>
                  )
                )
              } else {
                return (
                  !item.hidden && (
                    <a
                      className={item.level && item.level > 2 ? "anchor subSection" : "anchor"}
                      href={"#" + item.slug /* basicSlug(heading)*/}
                      key={i}
                    >
                      {heading.replace(/^[ \t]+|[*_^#+{}\\]+|[ \t]+$/gi, '')}
                    </a>
                  )
                )
              }


            })}
          </Scrollspy>
        </SectionMenuInnerContainer>
      </SectionMenuContainer>
    )
  }
}

export default SectionMenu
