import React from "react"
import Loadable from 'react-loadable';

const Video = Loadable({
  loader: () => import('./video'),
  loading() {
    return null;
  },
});

export default props => {
  return <Video src={props.src} />
}
