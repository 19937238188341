import React, { useEffect, useState, useRef } from "react"
import styled from "@emotion/styled"
import Helmet from "react-helmet"
import { trackWindowScroll } from "react-lazy-load-image-component"
import OnImagesLoaded from "react-on-images-loaded"
import GeoContext from "../../context/geoContext"
import {
  basicSlug,
  dynamicString,
  getAffiliateLinkWithMetadata,
  trustedIcons,
  depositIcons,
} from "../../../utils/index"
import cloudinary from "../../../utils/cloudinary"
import Layout from "../../components/layout"
import Breadcrumbs from "../../components/breadcrumbs"
import SectionMenu from "../../components/sectionMenu"
import ReviewStickyBar from "../../components/reviewStickyBar"
import ReviewRestrictedBar from "../../components/reviewRestrictedBar"
import { StickyContainer, Sticky } from "react-sticky"
import { Grid, Row, Col } from "react-flexbox-grid"
import ScoreBars from "../../components/scoreBars"
import ScrollInNav from "../../components/scrollInNav"
import VerticalsDropdown from "../../components/verticalsDropdown"
import Faq from "../../components/faq"
import ProviderTaxonomies from "../../components/providerTaxonomies"
import PostGrid from "../../components/postGrid"
import LoadableVideo from "../../components/loadableVideo"
import FeaturedProviders from "../../components/featuredProviders"

import {
  ArrowRight,
  HeroSection,
  MarkdownContent,
  CtaButton,
  StyledSection,
  SmallLabel,
} from "../../components/styledComponents"


const Provider = (props) => {
  const { pageContext, scrollPosition } = props
  const {
    site,
    provider,
    modules,
    isExtendedReviewSubPage,
    extendedReviewSection,
    themeSettings,
  } = pageContext
  const {
    basicReviewSectionsMenu,
    extendedReview = {},
    verticalLinks,
    postList,
  } = modules
  const { complianceMode, disableBonuses, disableReviewSections = [] } = themeSettings
  const { isExtendedReview } = extendedReview
  const { properties } = provider
  const restrictedCountries = properties?.["restricted-countries"]?.data?.value
  const backgroundColor = properties["background-color"]?.data?.value
  const scoreParameters = [
    "game-selection",
    "bonus-offers",
    "payment-options",
    "casino-software",
    "customer-service",
    "mobile-friendliness",
    "live-dealer-games",
    "total-score",
  ]
  const numberOfPropertiesToShow = 2
  const [sidebarScrollInThreshold, setSidebarScrollInThreshold] = useState(1000)
  const [
    sidebarScrollInBottomThreshold,
    setSidebarScrollInBottomThreshold,
  ] = useState(1000)
  const [expandedCount, setExpandedCount] = useState(0)
  const [mainColumnMinHeight, setMainColumnMinHeight] = useState("130vh")
  const sidebarScrollInToggleObj = useRef()
  const sidebarScrollInBottomObj = useRef()
  const rtl = site?.properties?.rtl?.data?.value

  useEffect(() => {
    if (typeof window !== "undefined") {
      setSidebarScrollInThreshold(
        window.pageYOffset +
          sidebarScrollInToggleObj?.current?.getBoundingClientRect().top
      )
      setSidebarScrollInBottomThreshold(
        window.pageYOffset +
          sidebarScrollInBottomObj?.current?.getBoundingClientRect().top
      )
    }
  }, [expandedCount])

  useEffect(() => {
    const _mainColumnMinHeight = document.getElementById('rightColumn')?.clientHeight - 200;
    setMainColumnMinHeight(_mainColumnMinHeight)
  }, [])

  let htmlTitle = isExtendedReviewSubPage
    ? extendedReviewSection?.["html-title"] || provider["html-title"]
    : provider["html-title"]

  htmlTitle = htmlTitle?.replace("%NAME", provider.name)
  htmlTitle = htmlTitle?.replace(
    "%ABBRNICHE",
    site?.vertical?.content?.find((c) => c.key === "abbreviated-name")
      ?.strings?.[0]?.data
  )
  htmlTitle = htmlTitle?.replace("[%s:count] ", "")

  const h1 = ({ provider, isExtendedReviewSubPage, extendedReviewSection }) => {
    let _h1 = provider["title"] ? provider["title"] : provider.name

    if (isExtendedReviewSubPage && extendedReviewSection?.["localizedName"]) {
      _h1 = _h1 + " - " + extendedReviewSection?.["localizedName"]
    }
    return _h1
  }

  return (
    <Layout
      {...props}
      headerBackground={"#fff"}
      htmlTitle={htmlTitle}
      metaDescription={
        isExtendedReviewSubPage
          ? extendedReviewSection?.["meta-description"] ||
            provider["meta-description"]
          : provider["meta-description"]
      }
      image={provider?.properties?.logotype?.resolved?.[0]?.url}
      path={
        isExtendedReviewSubPage ? extendedReviewSection.path : provider.path
      }
      scrollPosition={scrollPosition}
    >
      <OnImagesLoaded
        onLoaded={() => {
          if (typeof window !== "undefined") {
            setSidebarScrollInThreshold(
              window.pageYOffset +
                sidebarScrollInToggleObj?.current?.getBoundingClientRect().top
            )
            setSidebarScrollInBottomThreshold(
              window.pageYOffset +
                sidebarScrollInBottomObj?.current?.getBoundingClientRect().top
            )
          }
        }}
        timeout={7000}
      >
        <GeoContext.Consumer>
          {(geoContext) => {
            return (
              <React.Fragment>
                <div
                  className={
                    complianceMode &&
                    isRestricted({ geoContext, site, restrictedCountries })
                      ? "complianceModeHidden"
                      : null
                  }
                >
                  {!isExtendedReviewSubPage &&
                  provider?.properties?.["total-score"]?.data ? (
                    <Helmet>
                      <script type="application/ld+json">
                        {JSON.stringify(jsonSchema(props))}
                      </script>
                    </Helmet>
                  ) : null}
                  <HeroSection hero={1} pattern={1}>
                    <Grid>
                      <Breadcrumbs {...props} invert={1} />
                      <h1 className="my-3">
                        {h1({
                          provider,
                          isExtendedReviewSubPage,
                          extendedReviewSection,
                        })}
                      </h1>

                      <Row>
                        {/*isRestricted({
                          geoContext,
                          site,
                          restrictedCountries,
                        }) ? (
                          <Col md={12} className="pr-2 pr-md-0 mx-0">
                            <Box
                              className="mb-4 p-3"
                              style={{ overflow: "hidden" }}
                            >
                              <h3>
                                {(
                                  provider?.[
                                    "restricted-country-modal-title"
                                  ] ||
                                  site?.["restricted-country-modal-title"] ||
                                  "%PROVIDER is not available in your country. Please try:"
                                )?.replace("%PROVIDER", provider?.name)}
                              </h3>

                              <FeaturedProviders
                                {...props}
                                providers={geoContext?.providers}
                                count={3}
                                filterRestricted={
                                  site?.properties?.country?.data ? true : false
                                }
                                rating={true}
                                flag={true}
                                reviewLinks={themeSettings?.reviewLinks}
                                depositMethods={true}
                                outboundClickPrefix="restricted_top_"
                                resourceId={provider?.allanId}
                              />
                            </Box>
                          </Col>
                              ) : null*/}

                        <Col md={9}>
                          <SummaryBoxComponent
                            site={site}
                            backgroundColor={backgroundColor}
                            rtl={rtl}
                            scoreParameters={scoreParameters}
                            properties={properties}
                            numberOfPropertiesToShow={numberOfPropertiesToShow}
                            geoContext={geoContext}
                            provider={provider}
                            restrictedCountries={restrictedCountries}
                            disableBonuses={disableBonuses}
                            {...props}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={3}
                          className="pl-md-0 pl-xl-3"
                          style={{ zIndex: "9", position: "relative" }}
                        >
                          <RightColumnComponent
                            props={{
                              ...props,
                              site,
                              provider,
                              verticalLinks,
                              expandedCount,
                              setExpandedCount,
                              geoContext,
                              restrictedCountries,
                              postList,
                              scrollPosition,
                              sidebarScrollInThreshold,
                              sidebarScrollInBottomThreshold,
                              sidebarScrollInToggleObj,
                              rtl,
                              scoreParameters,
                              properties,
                              numberOfPropertiesToShow,
                            }}
                          />
                        </Col>
                      </Row>
                    </Grid>
                  </HeroSection>

                  <div className="d-md-none">
                    <ScrollInNav scrollInHeight={200} top={true}>
                      <SectionMenu
                        reviewSections={reviewSections({
                          provider,
                          isExtendedReview,
                          isExtendedReviewSubPage,
                          extendedReview,
                          extendedReviewSection,
                          basicReviewSectionsMenu,
                          site,
                          disableBonuses,
                          disableReviewSections
                        })}
                      />
                    </ScrollInNav>
                  </div>

                  <StickyContainer>
                    <StyledSection>
                      <MainContentWrapper style={{minHeight: mainColumnMinHeight }}>
                        <MainContent md={9} className="px-1">
                          <MainContentComponent
                            props={{
                              provider,
                              isExtendedReview,
                              isExtendedReviewSubPage,
                              extendedReview,
                              extendedReviewSection,
                              basicReviewSectionsMenu,
                              site,
                              scoreParameters,
                              geoContext,
                              restrictedCountries,
                              rtl,
                              postList,
                              scrollPosition,
                              disableBonuses,
                              disableReviewSections
                            }}
                          />
                        </MainContent>
                      </MainContentWrapper>
                    </StyledSection>
                  </StickyContainer>

                  <div
                    ref={sidebarScrollInBottomObj}
                    style={{ display: "block" }}
                  />
                </div>

                {isRestricted({
                  geoContext,
                  site,
                  restrictedCountries,
                }) ? (
                  <ReviewRestrictedBar props={{ provider, site, ...props }} />
                ) : null}
              </React.Fragment>
            )
          }}
        </GeoContext.Consumer>
      </OnImagesLoaded>
    </Layout>
  )
}

export default trackWindowScroll(Provider)

function MainContentComponent({ props }) {
  const {
    provider,
    isExtendedReview,
    isExtendedReviewSubPage,
    extendedReview,
    extendedReviewSection,
    basicReviewSectionsMenu,
    site,
    geoContext,
    restrictedCountries,
    rtl,
    disableBonuses = false,
    disableReviewSections = []
  } = props

  return (
    <>
      <div
        className="d-flex flex-column flex-md-row"
        style={{ overflow: "hidden" }}
      >
        {depositIcons({ provider })?.length ? (
          <div className="p-0 py-md-4 px-md-3">
            <SmallLabel className="d-block mb-2">
              {provider?.properties?.["deposit-method-taxonomies"]?.fieldName}
            </SmallLabel>
            <div className="d-flex align-items-center">
              {depositIcons({ provider })?.map((item) => {
                return (
                  <img
                    src={item.url}
                    alt={item.name}
                    className="mr-3"
                    style={{ height: "20px" }}
                  />
                )
              })}
            </div>
          </div>
        ) : null}

        {trustedIcons({ provider })?.length ? (
          <div className="p-0 py-4 px-md-3 ml-md-4">
            <SmallLabel className="d-block mb-2">
              {site?.["trusted-by"] || "Trusted by"}
            </SmallLabel>
            <div className="d-flex align-items-center">
              {trustedIcons({ provider })?.map((item) => {
                return (
                  <img
                    src={item.url}
                    alt={item.name}
                    className="mr-3"
                    style={{ height: "20px" }}
                  />
                )
              })}
            </div>
          </div>
        ) : null}
      </div>

      <Row>
        <Col
          xs={12}
          md={3}
          className="d-none d-md-block pt-4 pl-0"
          style={{ zIndex: "99" }}
        >
          <Sticky isActive={false}>
            {({ style }) => (
              <div style={style}>
                <SectionMenu
                  transparent
                  reviewSections={reviewSections({
                    provider,
                    isExtendedReview,
                    isExtendedReviewSubPage,
                    extendedReview,
                    extendedReviewSection,
                    basicReviewSectionsMenu,
                    site,
                    disableBonuses,
                    disableReviewSections
                  })}
                />
              </div>
            )}
          </Sticky>
        </Col>
        <MainCol xs={12} md={9}>
          {(!isExtendedReview && (
            <Card>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: provider.content,
                }}
              />
            </Card>
          )) ||
            null}

          {isExtendedReview &&
            !isExtendedReviewSubPage &&
            extendedReview?.data?.map((section, i) => {
              if (!section.intro) return null

              const bonusGroups = ["Promotions & Offers", "Bonuses"]
              if (disableBonuses && bonusGroups.indexOf(section.group) > -1) {
                return null
              }

              if (disableReviewSections?.indexOf(section?.group) > -1) return null

              return (
                <Card key={i}>
                  <h2 id={basicSlug(section.group)}>{section.localizedName}</h2>
                  <MarkdownContent
                    dangerouslySetInnerHTML={{
                      __html: section.intro,
                    }}
                  />
                  {basicSlug(section.group) === "about" &&
                  provider?.properties?.["video-about"]?.data?.value ? (
                    <LoadableVideo
                      src={provider?.properties?.["video-about"]?.data?.value}
                    />
                  ) : null}
                  {section.extended ? (
                    <ReadMoreLink href={section.path}>
                      {section["read-more-text"]}
                      <ArrowRight />
                    </ReadMoreLink>
                  ) : null}
                </Card>
              )
            })}

          {isExtendedReviewSubPage ? (
            <React.Fragment>
              <Card key={1}>
                <h2 id={basicSlug(extendedReviewSection.group)}>
                  {extendedReviewSection.localizedName}
                </h2>

                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: extendedReviewSection?.intro,
                  }}
                />

                {provider?.properties?.[
                  `video-${extendedReviewSection.group?.toLowerCase()}`
                ]?.data?.value ? (
                  <LoadableVideo
                    src={
                      provider?.properties?.[
                        `video-${extendedReviewSection.group?.toLowerCase()}`
                      ]?.data?.value
                    }
                  />
                ) : null}

                {extendedReviewSection?.group?.toLowerCase() === "faq" ? (
                  <Faq content={extendedReviewSection?.extended} />
                ) : (
                  <MarkdownContent
                    dangerouslySetInnerHTML={{
                      __html: extendedReviewSection?.extended,
                    }}
                  />
                )}
              </Card>

              {extendedReviewSection.content?.map((section) => {
                if (!section.content) return null
                return (
                  <Card>
                    <h2 id={basicSlug(section.key)}>
                      {section.title || section.key}
                    </h2>
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: section.content,
                      }}
                    />
                  </Card>
                )
              })}

              {/* {extendedReviewSection.properties?.map(
                (section) => {
                  if (!section.property) return;
                  return (
                    <Card>
                      <h2 id={basicSlug(section.key)}>
                        {section.title || section.key}
                      </h2>
                      {JSON.stringify(section.property)}
                    </Card>
                  );
                }
              )} */}
            </React.Fragment>
          ) : null}

          {!isRestricted({
            geoContext,
            site,
            restrictedCountries,
          }) && provider?.properties?.["affiliate-link"]?.data?.value ? (
            <ScrollInNav scrollInHeight={250} top={false} rtl={rtl}>
              <ReviewStickyBar props={{ provider, site, disableBonuses }} />
            </ScrollInNav>
          ) : null}
        </MainCol>
      </Row>
    </>
  )
}

const RightColumn = styled.div`
  max-width: 100%;
  position: relative;
  top: 50px;

  @media only screen and (min-width: 768px) {
    position: absolute;
    top: auto;
  }
`

function RightColumnComponent({ props }) {
  const {
    provider,
    verticalLinks,
    site,
    expandedCount,
    setExpandedCount,
    geoContext,
    restrictedCountries,
    postList,
    scrollPosition,
    sidebarScrollInToggleObj,
    sidebarScrollInThreshold,
    sidebarScrollInBottomThreshold,
    rtl,
    scoreParameters,
  } = props

  return (
    <RightColumn id="rightColumn">
      {(provider?.properties?.["total-score"] && (
        <Properties className="px-3 py-3 mb-3">
          <ScoreBars
            item={provider}
            scoreProperties={scoreParameters}
            solid={1}
            title={
              site?.["rating-heading"] ||
              provider?.properties?.["total-score"]?.fieldName
            }
          />
        </Properties>
      )) ||
        null}

      {verticalLinks && verticalLinks.length > 1 ? (
        <VerticalsDropdown items={verticalLinks} block {...props} />
      ) : null}

      <Properties className="px-3 py-3 mb-3">
        <div className="prosConsTitle mb-1">
          <svg
            width="15px"
            height="14px"
            viewBox="0 0 15 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-1077.000000, -743.000000)">
                <rect
                  fill="#F1F1FA"
                  x="0"
                  y="0"
                  width="1440"
                  height="10210"
                ></rect>
                <g transform="translate(1058.000000, 725.000000)">
                  <path
                    d="M0,0 L285,0 L285,302 C285,304.209139 283.209139,306 281,306 L4,306 C1.790861,306 2.705415e-16,304.209139 0,302 L0,0 L0,0 Z"
                    id="Rectangle"
                    fill="#F7F7FF"
                  ></path>
                  <g transform="translate(18.933566, 17.000000)" fill="#3DCD84">
                    <path
                      d="M0.996503497,8 C0.996503497,4.136 4.12153846,1 7.97202797,1 C11.8225175,1 14.9475524,4.136 14.9475524,8 C14.9475524,11.864 11.8225175,15 7.97202797,15 C4.12153846,15 0.996503497,11.864 0.996503497,8 Z M8.96890082,9.00360221 L11.1608392,9.00360221 C11.5993007,9.00360221 11.958042,8.44 11.958042,8 C11.958042,7.56 11.5993007,7.00971785 11.1608392,7.00971785 L8.96890082,7.00971785 L8.96890082,4.8 C8.96890082,4.36 8.41048951,4 7.97202797,4 C7.53356643,4 6.97964502,4.36 6.97964502,4.8 L6.97964502,7.00971785 L4.78321678,7.00971785 C4.34475524,7.00971785 3.98601399,7.56 3.98601399,8 C3.98601399,8.44 4.34475524,9.00360221 4.78321678,9.00360221 L6.97964502,9.00360221 L6.97964502,11.2 C6.97964502,11.64 7.53356643,12 7.97202797,12 C8.41048951,12 8.96890082,11.64 8.96890082,11.2 L8.96890082,9.00360221 Z"
                      id="plus-circle"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          {site?.["pros"] || "Pros"}
        </div>
        {getPros({ provider })?.map((p) => {
          if (!p) return null
          return <div className="prosConsItem my-3">+ {p}</div>
        })}

        <div
          className="prosConsTitle mt-3 pt-3"
          style={{ borderTop: "1px solid #e2e2ec" }}
        >
          <svg
            width="15px"
            height="14px"
            viewBox="0 0 15 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-1077.000000, -913.000000)">
                <rect
                  fill="#F1F1FA"
                  x="0"
                  y="0"
                  width="1440"
                  height="10210"
                ></rect>
                <g transform="translate(1058.000000, 725.000000)">
                  <path
                    d="M0,0 L285,0 L285,302 C285,304.209139 283.209139,306 281,306 L4,306 C1.790861,306 2.705415e-16,304.209139 0,302 L0,0 L0,0 Z"
                    id="Rectangle"
                    fill="#F7F7FF"
                  ></path>
                  <g
                    transform="translate(18.933566, 187.000000)"
                    fill="#E13333"
                    opacity="0.800000012"
                  >
                    <g>
                      <path
                        d="M7.97202797,1 C11.829493,1 14.9475524,4.129 14.9475524,8 C14.9475524,11.871 11.829493,15 7.97202797,15 C4.11456294,15 0.996503497,11.871 0.996503497,8 C0.996503497,4.129 4.11456294,1 7.97202797,1 Z M9.96153846,7 L5.98251748,7 C5.43023273,7 4.98251748,7.44771525 4.98251748,8 C4.98251748,8.55228475 5.43023273,9 5.98251748,9 L5.98251748,9 L9.96153846,9 C10.5138232,9 10.9615385,8.55228475 10.9615385,8 C10.9615385,7.44771525 10.5138232,7 9.96153846,7 L9.96153846,7 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          {site?.["cons"] || "Cons"}
        </div>
      </Properties>

      <Properties className="my-3 mb-md-0 mt-md-0 p-3">
        <h3 style={{ fontSize: "20px" }}>
          {site["provider-properties-heading"] || "Quick Facts"}
        </h3>
        <div
          className="py-2 mb-3"
          style={{ borderBottom: "1px solid #e2e2ec" }}
        >
          <div className="d-flex flex-row align-items-center mb-3">
            <img src="/propIcons/globe.svg" className="mr-2" alt="Website" />
            <span className="propertyName mr-1">
              {provider?.properties?.["website-url"]?.fieldName || "Website"}:
            </span>
            <span className="propertyValue">
              {isRestricted({ geoContext, site, restrictedCountries }) ? (
                "-"
              ) : provider?.properties?.["affiliate-link"]?.data?.value ? (
                <a
                  href={provider.properties["affiliate-link"].data.value}
                  target="_blank"
                  rel="noindex nofollow noopener noreferrer"
                  className="outlink"
                >
                  {provider.name}
                </a>
              ) : (
                "-"
              )}
            </span>
          </div>

          <div className="d-flex flex-row align-items-center mb-3">
            <img
              src="/propIcons/note.svg"
              className="mr-2"
              alt={"Year founded"}
            />
            <span className="propertyName mr-1">
              {provider?.properties?.["year-founded"]?.fieldName ||
                "Year founded"}
              :
            </span>
            <span className="propertyValue">
              {provider?.properties?.["year-founded"]?.data?.value || "-"}
            </span>
          </div>
        </div>

        <ProviderTaxonomies
          {...props}
          expandedCount={expandedCount}
          setExpandedCount={setExpandedCount}
        />
      </Properties>

      <div className="mt-3 d-none d-md-block">
        <CtaButtonComponent
          props={{ geoContext, site, restrictedCountries, provider }}
        />
      </div>

      {postList?.length ? (
        <PostGrid
          {...props}
          posts={postList}
          scrollPosition={scrollPosition}
          md={12}
          className="px-0 mt-3 d-none d-md-block"
          multiline={true}
        />
      ) : null}

      <div ref={sidebarScrollInToggleObj} className="d-block" />
      {provider?.properties?.["total-score"]?.data ? (
        <ScrollInNav
          scrollInHeight={sidebarScrollInThreshold + 10}
          top={true}
          stop={sidebarScrollInBottomThreshold}
          rtl={rtl}
        >
          <Grid className="mb-5 mt-2 d-none d-md-block">
            <Row>
              <Col md={3} mdOffset={9} className="pl-3">
                {(provider?.properties?.["total-score"] && (
                  <Properties className="px-3 py-3 mb-3 d-none d-md-block bg-white">
                    <ScoreBars
                      item={provider}
                      scoreProperties={scoreParameters}
                      solid={1}
                      title={
                        site?.["rating-heading"] ||
                        provider?.properties?.["total-score"]?.fieldName
                      }
                    />
                  </Properties>
                )) ||
                  null}

                <div className="mt-3">
                  <CtaButtonComponent
                    props={{ geoContext, site, restrictedCountries, provider }}
                  />
                </div>
              </Col>
            </Row>
          </Grid>
        </ScrollInNav>
      ) : null}
    </RightColumn>
  )
}

function SummaryBoxComponent(props) {
  const {
    provider,
    site,
    backgroundColor,
    rtl,
    geoContext,
    restrictedCountries,
    disableBonuses,
    pageContext,
  } = props
  const { themeSettings } = pageContext

  return (
    <SummaryBox className="d-flex flex-column flex-md-row py-4 px-3">
      <img
        src={
          themeSettings?.ageLimitIconWhite
            ? themeSettings.ageLimitIconBlack
            : "/img/18plus_bl.svg"
        }
        id="ageLimitIcon"
        alt="Age Limit"
      />

      <Col className="logoContainer center-xs left-md mr-md-3">
        <Logo
          src={cloudinary.url(
            provider?.properties?.logotype?.resolved?.[0]?.url,
            { height: 360 }
          )}
          className="mb-0"
          backgroundColor={backgroundColor}
          alt={provider?.name}
        />
      </Col>
      <Col
        className={
          "d-flex flex-column position-relative flex-grow-1 " +
          (rtl ? "text-md-right" : "text-md-left")
        }
      >
        {!disableBonuses ? (
          <div>
            {provider?.["bonus"] || provider?.["bonus-text"] ? (
              <span className="bonus-heading">
                {site?.["bonus-heading"] || "Bonus"}
              </span>
            ) : null}
            <span className="bonus d-block">
              {provider?.["bonus"] || provider?.["bonus-text"]}
            </span>
          </div>
        ) : null}

        <div className="detailsContainer d-flex flex-column flex-md-row mt-3 mt-md-0">
          <div className="details">
            <div className="detail mb-2">
              <img
                src="/img/depositSmall.svg"
                alt={site?.["deposit-requirements"] || "Deposit requirements"}
              />
              <span className="label">
                {site?.["deposit-requirements"] || "Deposit requirements"}:
              </span>
              <span className="value">-</span>
            </div>
            <div className="d-flex flex-column flex-md-row">
              <div className="detail mr-5 mb-2 mb-md-0">
                <img
                  src="/img/payoutSmall.svg"
                  alt={site?.["percentage-payout"] || "Percentage payout"}
                />
                <span className="label">
                  {site?.["percentage-payout"] || "Percentage payout"}:
                </span>
                <span className="value">-</span>
              </div>
              <div className="detail">
                <img
                  src="/img/minDepositSmall.svg"
                  alt={site?.["min-deposit"] || "Min Deposit"}
                />
                <span className="label">
                  {site?.["min-deposit"] || "Min Deposit"}:
                </span>
                <span className="value">-</span>
              </div>
            </div>
          </div>
          <div className="ctaContainer mt-3 mt-md-0 pl-5">
            <CtaButtonComponent
              props={{ geoContext, site, restrictedCountries, provider }}
            />
          </div>
        </div>
      </Col>
    </SummaryBox>
  )
}

function CtaButtonComponent({ props }) {
  let { geoContext, site, restrictedCountries, provider } = props
  
  const nakedAffiliateLink =
    provider?.properties?.["affiliate-link"]?.data?.value

  // Link T&C string template if tc-url is set
  const tcUrl = provider?.properties?.["tc-url"]?.data?.value
  if (tcUrl && provider?.["tc-text"] && provider?.["tc-text"]?.indexOf("<a") === -1) {
    provider["tc-text"] = `<a href=${tcUrl} rel="noindex nofollow noopener noreferrer">${provider?.["tc-text"]}</a>`
  } else if (provider?.["tc-text"] && provider?.["tc-text"]?.indexOf("<a href") > -1) {
    provider["tc-text"] = provider["tc-text"]?.replace(/<a href/g, "<a rel='noindex nofollow noopener noreferrer' href")
  }

  return (
    <>
      {!isRestricted({
        geoContext,
        site,
        restrictedCountries,
      }) && provider?.properties?.["affiliate-link"]?.data?.value ? (
        <React.Fragment>
          <CtaButton
            className="outlink glow d-block"
            href={getAffiliateLinkWithMetadata({
              affiliateLink: nakedAffiliateLink,
              position: "cta_top",
              resourceId: provider?.allanId,
            })}
            onClick={() => {
              window._paq.push([
                "trackEvent",
                `cta_top`,
                provider?.name,
                "Outclick: Button",
              ])
            }}
            target="_blank"
            rel="noindex nofollow noopener noreferrer"
          >
            {dynamicString({
              provider: provider,
              inputString: site["provider-page-cta"] || site["cta"],
            }) ||
              site["provider-page-cta"] ||
              site["cta"] ||
              "Get your bonus"}
          </CtaButton>
          {provider["review-disclaimer"] ||
          (provider["tc-text"] && provider["tc-text"] !== "undefined") ? (
            <div
              className="terms text-left mt-1 pl-0"
              dangerouslySetInnerHTML={{
                __html: provider["review-disclaimer"] || provider["tc-text"],
              }}
            />
          ) : null}
        </React.Fragment>
      ) : null}
    </>
  )
}

function jsonSchema(props) {
  const { pageContext } = props
  const { provider, site } = pageContext

  if (!provider?.properties?.["total-score"]?.data) {
    return null
  }

  return {
    "@context": "http://schema.org/",
    "@type": "Review",
    name: provider.name,
    reviewRating: {
      "@type": "Rating",
      ratingValue: parseFloat(
        provider?.properties?.["total-score"]?.data?.value / 2
      ).toFixed(1),
      worstRating: 0,
      bestRating: 5,
    },
    author: {
      "@type": "Organization",
      name: site?.properties?.name?.data?.value,
    },
    publisher: {
      "@type": "Organization",
      name: site?.properties?.name?.data?.value,
    },
    itemReviewed: {
      "@type": "Product",
      name: provider.name,
      description: provider["reviewContentForSchemaOrg"],
      brand: {
        "@type": "Thing",
        name: provider.name,
      },
      image: provider?.properties?.logotype?.resolved?.[0]?.url,
      review: {
        author: {
          "@type": "Thing",
          name: site?.properties?.name?.data?.value,
        },
        datePublished: provider.createdAt,
        dateModified: provider.createdAt,
        reviewBody: provider["reviewContentForSchemaOrg"],
      },
    },
  }
}

function getPros({ provider }) {
  if (provider["pros"]) {
    const pros =
      provider?.pros.indexOf(",") > -1
        ? provider?.["pros"]?.split(",")
        : [provider?.["pros"]]
    return pros
  }

  if (provider["main-usp"]) {
    const mainUsps =
      provider["main-usp"].indexOf("\n") > -1
        ? provider["main-usp"].split("\n")
        : [provider["main-usp"]]
    return mainUsps
  }
}

const reviewSections = ({
  isExtendedReview,
  extendedReview,
  isExtendedReviewSubPage,
  extendedReviewSection,
  basicReviewSectionsMenu,
  provider,
  site,
  disableBonuses,
  disableReviewSections
}) => {
  let menuItems = []

  if (isExtendedReview && !isExtendedReviewSubPage) {
    // Extended review root page
    extendedReview.data.forEach((section) => {
      const bonusGroups = ["Promotions & Offers", "Bonuses"]
      if (disableBonuses && bonusGroups.indexOf(section.group) > -1) {
        return
      }

      if (disableReviewSections?.indexOf(section?.group) > -1) return null

      if (!section.intro) return
      menuItems.push({
        heading: section.localizedName,
        slug: basicSlug(section.group),
        anchorOnly: true,
      })
    })

    return menuItems
  } else if (isExtendedReview && isExtendedReviewSubPage) {
    // Extended review sub page

    menuItems.push({
      heading: site?.["review-label"]
        ? provider.name + " " + site?.["review-label"]
        : provider.name,
      slug: provider.path,
      anchorOnly: true,
      isLink: true,
    })

    menuItems.push({
      heading: extendedReviewSection.localizedName,
      slug: basicSlug(extendedReviewSection.group),
      anchorOnly: true,
    })

    if (extendedReviewSection?.sectionsMenu?.length) {
      extendedReviewSection.sectionsMenu.forEach((s) => {
        menuItems.push({
          heading: s?.heading,
          slug: s?.slug,
          anchorOnly: true,
          level: s?.level,
        })
      })
    }

    return menuItems
  } else {
    // Basic review

    return basicReviewSectionsMenu
  }
}

function isRestricted({ restrictedCountries = [], geoContext, site }) {
  const siteCountry = site?.properties?.country?.data?.value
  return (
    restrictedCountries?.indexOf(geoContext.countryCode) > -1 ||
    (siteCountry && restrictedCountries?.indexOf(siteCountry) > -1)
  )
}


const Card = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid #e7e7ee;
  position: relative;
  margin-bottom: 20px;
`

const ReadMoreLink = styled.a`
  text-align: right;
  position: absolute;
  right: 0px;
  bottom: 0;
  background: ${(props) => props.theme.tertiaryColor};
  transform: translateY(50%);
  padding-left: 20px;
  color: #000;
  font-size: 14px;
  z-index: 9;
`

const Logo = styled.img`
  width: 125px;
  height: 125px;
  padding: 2px;
  object-fit: contain;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  border-radius: 50%;
  box-sizing: border-box;

  @media only screen and (min-width: 576px) {
  }

  @media only screen and (min-width: 992px) {
    height: 146px;
    width: 146px;
  }

  @media only screen and (min-width: 1200px) {
    height: 180px;
    width: 180px;
  }
`

const Properties = styled.div`
  color: #000;
  background: #fff;
  border-radius: ${(props) => props.theme.borderRadius};

  .item {
    margin-bottom: 10px;
  }
  .name {
    display: block;
    font-weight: 600;
  }
  .value {
    display: block;
  }

  .prosConsTitle {
    display: flex;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px;

    svg {
      margin-right: 10px;
    }
  }

  .prosConsItem {
    font-size: 14px;
    color: ${(props) => props.theme.textFaded};
  }

  @media only screen and (min-width: 768px) {
    background: #f7f7ff;
  }

  .propertyName {
    color: ${(props) => props.theme.textFaded};
    font-size: 14px;
  }

  .propertyValue {
    font-size: 14px;
    color: #000;
    a {
      color: #000;
      text-decoration: underline;
    }
  }
`

const MainCol = styled(Col)`
  z-index: 998 !important;
  background: ${(props) => props.theme.tertiaryColor};

  @media only screen and (min-width: 768px) {
    z-index: 99999 !important;
  }
`

const Box = styled.div`
  color: #000;
  background: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  position: relative;
`

const SummaryBox = styled.div`
  color: #000;
  background: #fff;
  border-radius: ${(props) => props.theme.borderRadius};
  position: relative;
  top: 0px;
  margin-bottom: 110px;

  @media only screen and (max-width: 768px) {
    padding-top: 60px !important;
    margin-bottom: 15px;
    top: 50px;
  }

  #ageLimitIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }

  .bonus-heading {
    color: ${(props) => props.theme.textFaded};
  }

  .bonus {
    color: #000;
    font-weight: bold;
    display: block;
    font-size: 30px;

    @media only screen and (max-width: 768px) {
      font-size: 25px;
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    transform: translateY(-40%) translateX(-50%);
    left: 50%;

    @media only screen and (min-width: 768px) {
      position: relative;
      top: unset;
      left: unset;
      transform: none;
    }
  }

  .detailsContainer {
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    @media only screen and (min-width: 992px) {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  .ctaContainer {
    width: 100%;

    @media only screen and (min-width: 768px) {
      max-width: 310px;
    }
  }

  .details {
    align-self: flex-start;
    font-size: 13px;

    @media only screen and (min-width: 768px) {
      align-self: flex-end;
    }

    .detail {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0;

      @media only screen and (min-width: 768px) {
        display: none !important;
      }
      @media only screen and (min-width: 992px) {
        display: flex !important;
      }
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    .label {
      color: ${(props) => props.theme.textFaded};
      margin-right: 5px;
    }

    .value {
      color: ${(props) => props.theme.primaryColor};
    }
  }
`

const MainContentWrapper = styled(Grid)`
  position: relative;
  padding-top: 50px;

  @media only screen and (min-width: 768px) {
    top: -100px;
    padding-top: 0;
  }
`

const MainContent = styled(Col)`
  background: ${(props) => props.theme.tertiaryColor};
  border-radius: ${(props) => props.theme.borderRadius};
  z-index: 99;
  position: relative;
`