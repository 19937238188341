import React, { useState } from "react"
import styled from "@emotion/styled"
import { PropertyChip, StaticPropertyChip } from "./styledComponents"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Properties = styled.div`
  .name {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: ${(props) => props.theme.textFaded};
  }
  .expandedIcon {
    font-weight: 600;
    color: ${(props) => props.theme.textFaded};
    font-size: 20px;
  }
  .value {
    display: block;
  }
`

const Item = styled.div`
  border-bottom: ${(props) => (props.expanded ? `1px solid #e7e7ee` : "none")};

  img {
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  > :focus {
    outline: none;
  }
`

function ExpandableSection({ icon, items, title }) {
  const [expanded, toggleExpanded] = useState(false)

  return (
    <Item
      className={"mb-3 " + (expanded ? "pb-2 mb-4" : "")}
      expanded={expanded}
    >
      <div
        className="d-flex"
        onClick={() => toggleExpanded(!expanded)}
        onKeyDown={() => toggleExpanded(!expanded)}
        style={{ cursor: "pointer", justifyContent: "space-between" }}
        role="button"
        tabIndex={0}
        aria-label="Expand section"
      >
        <span className="name d-flex align-items-center">
          <LazyLoadImage
            src={icon || "/img/settings.svg"}
            className="mr-2"
            alt={title}
          />{" "}
          {title}
        </span>

        <div className="expandedIcon">{expanded ? "-" : "+"}</div>
      </div>
      <div
        style={{ height: expanded ? "auto" : "0", overflow: "hidden" }}
        className={expanded ? "pt-2" : ""}
      >
        {items}
      </div>
    </Item>
  )
}

const ProviderTaxonomies = (props) => {
  const { pageContext, expandedCount, setExpandedCount } = props
  const { provider, themeSettings = {} } = pageContext
  const { disableBonuses = false, disableReviewTaxonomies = [] } = themeSettings

  const { properties = {} } = provider

  let fieldsToShow = [
    { id: "affiliate-program-taxonomies", icon: "/propIcons/note.svg" },
    { id: "software-taxonomies", icon: "/propIcons/software.svg" },
    { id: "deposit-method-taxonomies", icon: "/propIcons/insert.svg" },
    { id: "withdrawal-method-taxonomies", icon: "/propIcons/money.svg" },
    { id: "language-taxonomies", icon: "/propIcons/globe.svg" },
    { id: "country-taxonomies", icon: "/propIcons/flag.svg" },
    { id: "support-taxonomies", icon: "/propIcons/settings.svg" },
    { id: "license-taxonomies", icon: "/propIcons/check.svg" },
    { id: "casino-type-taxonomies", icon: "/propIcons/slots.svg" },
    { id: "currency-taxonomies", icon: "/propIcons/wallet.svg" },
    { id: "games-taxonomies", icon: "/propIcons/hand.svg" },
    { id: "bonuses-taxonomies", icon: "/propIcons/wallet.svg" },
  ]

  fieldsToShow = fieldsToShow.sort(function (a, b) {
    if (properties?.[a?.id]?.fieldName < properties?.[b?.id]?.fieldName) {
      return -1
    }
    if (properties?.[a?.id]?.fieldName > properties?.[b?.id]?.fieldName) {
      return 1
    }
    return 0
  })

  if (disableBonuses) {
    fieldsToShow = fieldsToShow.filter((item) => {
      if (item.id === "bonuses-taxonomies") {
        return false
      } else {
        return true
      }
    })
  }

  if (disableReviewTaxonomies?.length) {
    fieldsToShow = fieldsToShow.filter((item) => {
      if (disableReviewTaxonomies?.indexOf(item.id) > -1) {
        return false
      } else {
        return true
      }
    })
  }

  return (
    <Properties className="mt-0">
      {fieldsToShow.map((propertyField, i) => {
        const skipTypes = ["MEDIAITEM"]
        const property = properties[propertyField?.id]

        if (property && property.type === "TAXONOMYITEM") {
          let taxonomyItems = property?.resolved?.sort(function (a, b) {
            if (!a || !b) return 0
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
          let items = []

          taxonomyItems &&
            taxonomyItems.forEach((taxonomyItem, i) => {
              if (
                taxonomyItem?.path &&
                taxonomyItem?.published &&
                taxonomyItem?.name
              ) {
                items.push(
                  <PropertyChip
                    href={taxonomyItem?.path || "#"}
                    key={i}
                    className="p-2 mr-2 mb-2"
                  >
                    {taxonomyItem?.["list-title"] || taxonomyItem?.name}
                  </PropertyChip>
                )
              } else {
                if (taxonomyItem?.name) {
                  items.push(
                    <StaticPropertyChip key={i} className="p-2 mr-2 mb-2">
                      {taxonomyItem?.["list-title"] || taxonomyItem?.name}
                    </StaticPropertyChip>
                  )
                }
              }
            })

          return (
            <div
              onClick={() => {
                setExpandedCount(expandedCount + 1)
              }}
              onKeyDown={null}
              key={i}
              role="button"
              tabIndex={0}
              aria-label="Expand section"
            >
              <ExpandableSection
                {...props}
                key={i}
                title={
                  (property.fieldName || propertyField?.id) +
                  ` (${taxonomyItems?.length ? taxonomyItems?.length : "0"})`
                }
                items={items}
                icon={propertyField?.icon}
              />
            </div>
          )
        } else if (property && skipTypes.indexOf(property.type) === -1) {
          return (
            <div className="item" key={i}>
              <span className="name">
                {property.fieldName || propertyField.key}
              </span>
              <span className="value">{String(property.data?.value)}</span>
            </div>
          )
        }
        return null
      })}
    </Properties>
  )
}

export default ProviderTaxonomies
