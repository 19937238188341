import React from "react"
import styled from "@emotion/styled";
import { Grid } from "react-flexbox-grid"
import ProviderListSmall from "./providerListSmall"

const BottomStickyBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 999999;
  display: flex;
  flex-direction: column;

  .bonus {
    font-weight: 600;
    font-size: 22px;
  }

  .depositMethods {
    color: ${props => props.theme.textFaded};
    font-size: 12px;
  }

  @media only screen and (min-width: 768px) {
    .ctaButton {
      padding: 15px 80px;
    }
  }

  .topBar {
    background: ${props => props.theme.primaryColor};
    color: #fff;
    display: block;
    width: 100%;
    font-size: 14px;
  }
`

export default ({props}) => {
  const { provider, site } = props

  return (
    <BottomStickyBarContainer className="d-flex align-items-center px-0 pb-1">
      <div className="topBar py-2 text-center">
        <Grid className="d-flex align-items-center justify-content-center">
          <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="mr-2">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-1.000000, -1.000000)" fill="#81858B">
                    <path d="M8,1.33333333 C4.32,1.33333333 1.33333333,4.32 1.33333333,8 C1.33333333,11.68 4.32,14.6666667 8,14.6666667 C11.68,14.6666667 14.6666667,11.68 14.6666667,8 C14.6666667,4.32 11.68,1.33333333 8,1.33333333 Z M8,8.66666667 C7.63333333,8.66666667 7.33333333,8.36666667 7.33333333,8 L7.33333333,5.33333333 C7.33333333,4.96666667 7.63333333,4.66666667 8,4.66666667 C8.36666667,4.66666667 8.66666667,4.96666667 8.66666667,5.33333333 L8.66666667,8 C8.66666667,8.36666667 8.36666667,8.66666667 8,8.66666667 Z M8.66666667,11.3333333 L7.33333333,11.3333333 L7.33333333,10 L8.66666667,10 L8.66666667,11.3333333 Z"></path>
                </g>
            </g>
          </svg>
          {(
            provider?.["restricted-country-modal-title"] ||
            site?.["restricted-country-modal-title"] ||
            "%PROVIDER is not available in your country. Please try:"
          )?.replace("%PROVIDER", provider?.name)}
        </Grid>
      </div>
      <Grid className="px-0 d-flex" style={{justifyContent:"space-between"}}>

      <ProviderListSmall {...props} dynamic count={3} showBadges={false} horizontal={true} className="px-0" minimal={true} outboundClickPrefix="restricted_bottom_" resourceId={provider?.allanId} />

      </Grid>
    </BottomStickyBarContainer>
  )
}
